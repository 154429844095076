import { styled, Container } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { InView } from 'react-intersection-observer';
import { fadeInVariant, upVariant } from '../../components/AnimationVariants';
import IconList from '../../components/IconList';
import Layout from '../../components/Layout';
import ProjectNav from '../../components/ProjectNav';
import { SEO } from '../../components/seo';
import { StyledBoxContainer, StyledBoxItem } from '../../components/StyledBox';
import { StyledTypographySubtitle } from '../../components/StyledTypography';

const Appety: React.FC = () =>  {
  const StyledDiv = styled('div')(({theme}) => ({
    background: 'transparent linear-gradient(270deg, #FFFFFF 0%, #E8F8FF 100%) 0% 0% no-repeat padding-box',
    opacity: 1,
    [theme.breakpoints.down("md")]: {
      paddingBottom: "8vh",
    }
  }));

  const controls = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();
  const controls4 = useAnimation();


  return (
    <Layout>
      <StyledDiv>
        <Container maxWidth={'lg'} disableGutters>
          <ProjectNav />
          <InView as="div" onChange={(inView) => { inView ? controls.start("visible") : '' }} triggerOnce={true} threshold={0.2 }>
            <StyledBoxContainer sx={{ flexDirection: 'row', alignItems: 'center', padding: "8vh 0 8vh 0" }}>
              <StyledBoxItem>
                <motion.div animate={controls} initial="hidden" variants={fadeInVariant}>
                  <StaticImage alt="appety_menu" src="../../images/appety_menu2.jpg" style={{ borderRadius:16 }}/>
                </motion.div>
              </StyledBoxItem>
              <StyledBoxItem>
                <motion.div animate={controls} initial="hidden" variants={upVariant}>
                  <StyledTypographySubtitle gutterBottom component={"h3"}>
                    Appety Menu Web
                  </StyledTypographySubtitle>
                </motion.div>
                <IconList labels={["Web based menu and ordering system", "Scan the QR to browse the menu and let the customer to self order", "Shopping cart for order", "Bill with served-items tracking", "Can be used with or without Appety POS"]}/>
              </StyledBoxItem>
            </StyledBoxContainer>
          </InView>
          <InView as="div" onChange={(inView) => { inView ? controls2.start("visible") : '' }} triggerOnce={true} threshold={0.2}>
            <StyledBoxContainer sx={{ flexDirection: 'row-reverse', alignItems: 'center', padding: "8vh 0 8vh 0" }}>
              <StyledBoxItem>
                <motion.div animate={controls2} initial="hidden" variants={fadeInVariant}>
                  <StaticImage alt="appety_waiter" src="../../images/appety_waiter2.jpg" style={{ borderRadius:16 }}/>
                </motion.div>
              </StyledBoxItem>
              <StyledBoxItem>
                <motion.div animate={controls2} initial="hidden" variants={upVariant}>
                  <StyledTypographySubtitle gutterBottom component={"h3"}>
                    Appety Waiter
                  </StyledTypographySubtitle>
                </motion.div>
                <IconList labels={["Let the waiter provide their best service to customers with Appety Waiter", "Better order handling and tracking", "Track new incoming order", "Track which items have been served"]}/>
              </StyledBoxItem>
            </StyledBoxContainer>
          </InView>
          <InView as="div" onChange={(inView) => { inView ? controls3.start("visible") : '' }} triggerOnce={true} threshold={0.2}>
            <StyledBoxContainer sx={{ flexDirection: 'row', alignItems: 'center', padding: "8vh 0 8vh 0" }}>
              <StyledBoxItem>
                <motion.div animate={controls3} initial="hidden" variants={fadeInVariant}>
                  <StaticImage alt="appety_pos" src="../../images/appety_pos2.jpg" style={{ borderRadius:16 }}/>
                </motion.div>
              </StyledBoxItem>
              <StyledBoxItem>
                <motion.div animate={controls3} initial="hidden" variants={upVariant}>
                  <StyledTypographySubtitle gutterBottom component={"h3"}>
                    Appety POS
                  </StyledTypographySubtitle>
                </motion.div>
                <IconList labels={["Powerful and slick Point of Sale system, with focus on good UI/UX", "Work in offline and online mode. Suitable for area with poor internet connection", "Our team helped to design the UI/UX and architect the whole system"]}/>
              </StyledBoxItem>
            </StyledBoxContainer>
          </InView>
          <InView as="div" onChange={(inView) => { inView ? controls4.start("visible") : '' }} triggerOnce={true} threshold={0.2}>
            <StyledBoxContainer sx={{ flexDirection: 'row-reverse', alignItems: 'center', padding: "8vh 0 16vh 0" }}>
              <StyledBoxItem>
                <motion.div animate={controls4} initial="hidden" variants={fadeInVariant}>
                  <StaticImage alt="appety_admin" src="../../images/appety_admin2.jpg" style={{ borderRadius:16 }}/>
                </motion.div>
              </StyledBoxItem>
              <StyledBoxItem>
                <motion.div animate={controls4} initial="hidden" variants={upVariant}>
                  <StyledTypographySubtitle gutterBottom component={"h3"}>
                    Appety Admin
                  </StyledTypographySubtitle>
                </motion.div>
                <IconList labels={["Admin based for managing POS, menu, restaurant and order", "Intuitive UI for managing hundreds of menus", "Analytics", "Based on Nextjs, Material UI and graphql"]}/>
              </StyledBoxItem>
            </StyledBoxContainer>
          </InView>
        </Container>
      </StyledDiv>
    </Layout>
  );
}

export default Appety;

export const Head = () => (
  <SEO title="Projects | Garasilabs"/>
)